import store from '@/state/store';

import { authGuard } from "@auth0/auth0-vue";

import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/admin/musclegroups",
    name: "Muscle groups",
    meta: {
      title: "Muscle groups",
      authRequired: true,
    },
    component: () => import("../views/admin/musclegroups.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/admin/exercises",
    name: "Exercises",
    meta: {
      title: "Exercises",
      authRequired: true,
    },
    component: () => import("../views/admin/exercises.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/exercise/add",
    name: "Add exercise",
    meta: {
      title: "Add exercise",
      authRequired: true,
    },
    component: () => import("../views/exercise/add.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/admin/scoretypes",
    name: "WOD types",
    meta: {
      title: "WOD types",
      authRequired: true,
    },
    component: () => import("../views/admin/scoretypes.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/admin/wodcategories",
    name: "WOD categories",
    meta: {
      title: "WOD Categories",
      authRequired: true,
    },
    component: () => import("../views/admin/wodcategories.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/admin/workouts",
    name: "Workouts",
    meta: {
      title: "Workouts",
      authRequired: true,
    },
    component: () => import("../views/admin/workouts.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/workout/add",
    name: "Add workout",
    meta: {
      title: "Add workout",
      authRequired: true,
    },
    component: () => import("../views/workout/add.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/account/login.vue"),
    meta: {
      title: "Login",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("../views/account/register.vue"),
    meta: {
      title: "Register",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/",
    name: "WODHERO",
    meta: {
      title: "Dashboard",
    },
    component: () => import("../views/landingpage/index.vue"),
    beforeEnter: (routeTo, routeFrom, next) => {
      if(localStorage.getItem('user') === 'null'){
        console.log("TEST");
        next();
      }else{
        console.log("TEST2");
        next({ name: "app" });
      }
      console.log("localstorage: " + window.localStorage.getItem('user'));
    },
  },
  {
    path: "/logout",
    name: "logout",
    meta: {
      title: "Logout",
      beforeEnter(routeTo, next) {
        next({ name: "WODHERO" });
      },
    },
    component: () => import("../views/landingpage/index.vue"),
  },
  {
    path: "/home",
    name: "app",
    meta: {
      title: "Wodhero",
      authRequired: true,
    },
    component: () => import("../views/dashboard/index.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/workout/all",
    name: "All workouts",
    meta: {
      title: "All workouts",
      authRequired: true,
    },
    component: () => import("../views/workout/all.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/workout/:id",
    name: "Workout",
    meta: {
      title: "Workout",
      authRequired: true,
    },
    component: () => import("../views/workout/show/index.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/exercise/all",
    name: "All exercises",
    meta: {
      title: "All exercises",
      authRequired: true,
    },
    component: () => import("../views/exercise/all.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/exercise/:id",
    name: "Exercise",
    meta: {
      title: "Exercise",
      authRequired: true,
    },
    component: () => import("../views/exercise/show/index.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/gym/all",
    name: "All gyms",
    meta: {
      title: "All gyms",
      authRequired: true,
    },
    component: () => import("../views/gym/all.vue"),
    beforeEnter: authGuard,
  },
  {
    path: "/gym/:id/admin",
    name: "Gym administration",
    meta: {
      title: "Gym Adminstration",
      authRequired: true,
    },
    component: () => import("../views/gym/admin.vue"),
    beforeEnter: authGuard,
  },
];

const router = createRouter({
  history: createWebHistory('/'),
  routes,
});

export default router;
import { createAuth0 } from "@auth0/auth0-vue";
import { createApp } from 'vue';
import App from './App.vue';
import router from "./router";
import AOS from 'aos';
import 'aos/dist/aos.css';
import i18n from './i18n';
import store from "./state/store";
import BootstrapVue3 from 'bootstrap-vue-3';
import vClickOutside from "click-outside-vue3";
import VueApexCharts from "vue3-apexcharts";
import Maska from 'maska';
import 'vue-rate/dist/vue-rate.css'
import VueFeather from 'vue-feather';
import Particles from "particles.vue3";

import '@/assets/scss/config/interactive/app.scss';
import '@vueform/slider/themes/default.css';
AOS.init({
    easing: 'ease-out-back',
    duration: 1000
});

createApp(App)
    .use(store)
    .use(router)
    .use(VueApexCharts)
    .use(BootstrapVue3)
    .component(VueFeather.type, VueFeather)
    .use(Maska)
    .use(Particles)
    .use(i18n)
    .use(
        createAuth0({
          domain: process.env.VUE_APP_AUTH0_DOMAIN,
          client_id: process.env.VUE_APP_AUTH0_CLIENT_ID,
          redirect_uri: process.env.VUE_APP_AUTH0_REDIRECT_URL,
          audience: process.env.VUE_APP_AUTH0_AUDIENCE,
        })
      )
    .use(vClickOutside).mount('#app');